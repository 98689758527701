<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>
    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <div>
        <v-tabs v-model="tabs" color="blue">
          <v-tab v-for="item in tabItems" :key="item"> {{ item }} </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="home pa-3">
          <v-tab-item>
            <v-text-field
              v-model="basemaster.companyNm"
              label="会社名"
              prepend-icon="mdi-office-building"
              outlined
              :readonly="!edit"
              :rules="[Rules.Required]"
            ></v-text-field>
            <v-text-field
              v-model="basemaster.yuubin"
              label="郵便番号"
              prepend-icon="mdi-account"
              outlined
              :readonly="!edit"
            ></v-text-field>
            <v-text-field
              v-model="basemaster.address1"
              label="住所１"
              prepend-icon="mdi-home-map-marker"
              outlined
              :readonly="!edit"
            ></v-text-field>
            <v-text-field
              v-model="basemaster.address2"
              label="住所２"
              prepend-icon="mdi-home-map-marker"
              outlined
              :readonly="!edit"
            ></v-text-field>
            <v-text-field
              v-model="basemaster.tel"
              label="TEL"
              prepend-icon="mdi-phone"
              outlined
              :readonly="!edit"
            ></v-text-field>
            <v-text-field
              v-model="basemaster.fax"
              label="FAX"
              prepend-icon="mdi-fax"
              outlined
              :readonly="!edit"
            ></v-text-field>
            <div class="d-flex flex-row-reverse">
              <v-btn type="login" color="success" :disabled="!edit">
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="basemaster.kyuyoSime"
              label="給与締日"
              suffix="日"
              min="1"
              max="31"
              :rules="[Rules.Required]"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model="basemaster.kyuyoSikyu"
              label="給与支給日"
              suffix="日"
              min="1"
              max="31"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model="basemaster.kyuyoShift"
              label="シフト開始日"
              suffix="日"
              min="1"
              max="31"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model="basemaster.kyuyoRoundMinutes"
              label="丸め対象時間"
              suffix="分"
              min="0"
              max="59"
            ></v-text-field>
            <v-autocomplete
              v-model="basemaster.kyuyoTankaRound"
              :items="tankaRoundList"
              label="単価丸め区分"
              :rules="[Rules.Required]"
              prepend-icon="mdi-format-list-bulleted"
              outlined
              v-if="false"
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.kyuyoMoneyRound"
              :items="moneyRoundList"
              label="金額丸め区分"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              v-if="false"
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.kyuyoMonth"
              :items="monthList"
              label="残業(月)"
              :rules="[Rules.Required]"
              prepend-icon="mdi-format-list-bulleted"
              outlined
              :readonly="!edit"
              v-if="false"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.kyuyoWeek"
              :items="weekList"
              label="残業(週)"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              :readonly="!edit"
              v-if="false"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.kyuyoDay"
              :items="dayList"
              label="残業(日)"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              :readonly="!edit"
              v-if="false"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.overtimeFlag"
              :items="overtimeFlagList"
              label="残業計算フラグ"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              v-if="false"
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.breaktimeFlag"
              :items="breaktimeFlagList"
              label="休憩計算フラグ"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              v-if="false"
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.overtimeWeekKbn"
              :items="weekkbnList"
              label="週40時間の計算設定"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.yearEndNewFrom"
              :items="endnewyearList"
              label="年末年始手当の開始日"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="basemaster.yearEndNewTo"
              :items="endnewyearList"
              label="年末年始手当の終了日"
              prepend-icon="mdi-format-list-bulleted"
              :rules="[Rules.Required]"
              outlined
              :readonly="!edit"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <div class="d-flex flex-row-reverse">
              <v-btn type="login" color="success" :disabled="!edit">
                完了
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-form>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

const SelectsDelete = {
  Delete: "0",
  Default: "1"
};

export default {
  name: "BaseMasterDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      tabs: 0, // Opens Tab 1 by default. 0-indexed.
      tabItems: ["会社情報", "給与"],

      SelectsDelete,
      basemaster: {
        id: "",
        companyNm: "",
        yuubin: "",
        address1: "",
        address2: "",
        tel: "",
        fax: "",
        kyuyoSime: "",
        kyuyoSikyu: "",
        kyuyoShift: "",
        kyuyoRoundMinutes: "",
        kyuyoTankaRound: "",
        kyuyoMoneyRound: "",
        kyuyoMonth: "",
        kyuyoWeek: "",
        kyuyoDay: "",
        overtimeFlag: "",
        breaktimeFlag: "",
        isValid: SelectsDelete.Default
      },
      tankaRoundList: [],
      moneyRoundList: [],
      monthList: [],
      weekList: [],
      dayList: [],
      overtimeFlagList: [],
      breaktimeFlagList: [],
      weekkbnList: [],
      endnewyearList: [],
      isNumber: value => Number.isInteger(value) || "整数で入力してください。",
      isNull: value => value != null || "必須入力です。"
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    //更新処理
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.userId);
      this.$loading();

      try {
        const param = this.basemaster;

        const yefM = param.yearEndNewFrom.substring(0, 2);
        const yefD = param.yearEndNewFrom.substring(2, 4);
        const yetM = param.yearEndNewTo.substring(0, 2);
        const yetD = param.yearEndNewTo.substring(2, 4);

        if (parseInt(yefM) === parseInt(yetM)) {
          // 12月のみまたは1月のみのため次のチェックに進む
          if (parseInt(yefD) <= parseInt(yetD)) {
            // from <= to の場合は正常
          } else {
            this.$error("年末年始手当の範囲が異常です。");
            return;
          }
        } else if (parseInt(yefM) > parseInt(yetM)) {
          // from：12月、to:1月のため正常
        } else if (parseInt(yefM) < parseInt(yetM)) {
          // from：1月、to：12月のため異常
          this.$error("年末年始手当の範囲が異常です。");
          return;
        }

        console.log("submit_param", param);
        if (this.add) {
          //await this.$post(this.Paths.shain, param);
        } else {
          await this.$put(this.Paths.baseMaster, param);
        }
        this.$info("更新しました。", "基本情報マスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    //Listに戻る
    back() {
      const path = "/list/basemaster";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    //#region GetAPI
    async getTankaRoundList() {
      console.log("getTankaRoundList");
      const params = { kbn: "HASUU" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getMoneyRoundList() {
      console.log("getMoneyRoundList");
      const params = { kbn: "HASUU" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getMonthList() {
      console.log("getMonthList");
      const params = { kbn: "EXISTE" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getWeekList() {
      console.log("getWeekList");
      const params = { kbn: "WWS" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getDayList() {
      console.log("getDayList");
      const params = { kbn: "OVDAY" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getOvertimeFlagList() {
      console.log("getOvertimeFlagList");
      const params = { kbn: "OVFLG" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getBreaktimeFlagList() {
      console.log("getBreaktimeFlagList");
      const params = { kbn: "BRKFLG" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getOverWeekKbnList() {
      console.log("getOverWekkKbnList");
      const params = { kbn: "OWEEK" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getEndNewYearList() {
      console.log("getEndNewYearList");
      const params = { kbn: "ENY" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    }
    //#endregion
  },
  async created() {
    console.log(this.name, "created");
    const params = this.$route.params;
    const prosess = params.prosess;
    this.params = params;
    console.log("filter", this.params.filter);

    this.$loading();
    try {
      if (prosess && typeof prosess === "string")
        this.params.prosess = prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      const promiseList = [
        this.getTankaRoundList(),
        this.getMoneyRoundList(),
        this.getMonthList(),
        this.getWeekList(),
        this.getDayList(),
        this.getOvertimeFlagList(),
        this.getBreaktimeFlagList(),
        this.getOverWeekKbnList(),
        this.getEndNewYearList()
      ];
      const [
        tankaRound,
        moneyRound,
        month,
        week,
        day,
        overtimeFlag,
        breaktimeFlag,
        overweekList,
        endnewyear
      ] = await Promise.all(promiseList);

      this.tankaRoundList = tankaRound;
      this.moneyRoundList = moneyRound;
      this.monthList = month;
      this.weekList = week;
      this.dayList = day;
      this.overtimeFlagList = overtimeFlag;
      this.breaktimeFlagList = breaktimeFlag;
      this.weekkbnList = overweekList;
      this.endnewyearList = endnewyear;

      if (!this.add) {
        const args = this.args;
        this.basemaster = args;
        // this.incode = args.incode;
        // this.code = args.code;
        // this.name = args.name;
        // this.furi = args.furi;
        // this.password = args.password;
        // this.isValid = Number(args.isValid);
        // this.authority = args.authority;
        // this.kkubun = args.kkubun;
        // this.taimode = args.taimode;
        // this.sex = args.sex;
        // this.shozoku = args.shozoku;
        // this.daybirth = args.daybirth;
        // this.daybirth = args.daybirth;
        // this.daytai = args.daytai;
        // this.kyuhtan0 = args.kyuhtan0;
        // this.oldCode = args.oldCode;

        console.log("promise");
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style>
.base {
  margin-bottom: 30px;
}
.base1 basetab {
  width: 30px;
}
</style>
<!--<template>
  <div>
    <v-card>
      <v-tabs v-model="tabs">
        <v-tab v-for="item in tabItems" :key="item"> {{ item }} </v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item> Content 1 </v-tab-item>
          <v-tab-item> Content 2 </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "BaseMasterDetails",
  data: () => ({
    tabs: 0, // Opens Tab 1 by default. 0-indexed.
    tabItems: ["会社情報", "給与"]
  })
};
</script>
-->
